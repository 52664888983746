"use client";

import React from "react";
import { config } from "@/common/config";
import { usePathname, useSearchParams } from "next/navigation";
import useAnalytics from "@/hooks/useAnalytics";
import { useLoggedUser } from "@/state/user/useLoggedUser";
import useLoggedUserFunctions from "@/hooks/useLoggedUserFunctions";
import { findClosestRoute } from "@/utils/routes";
import { initDatadogRum, setDatadogRumUser } from "./datadog";
import { setSentryUser } from "./sentry";
import { identifyLogRocketUser, initLogRocket } from "./logrocket";
export default function TrackingProvider(props: React.PropsWithChildren) {
  const {
    loadAnalytics,
    track,
    identify
  } = useAnalytics();
  const {
    loggedUser
  } = useLoggedUser();
  const {
    getTrackingUserData
  } = useLoggedUserFunctions();
  React.useEffect(() => {
    if (config.environment === "production") {
      initLogRocket();
      initDatadogRum();
      loadAnalytics(config.rudderstack.writeKey!, config.rudderstack.dataPlaneUrl!);
    }
  }, []);
  React.useEffect(() => {
    if (!loggedUser) return;
    if (config.environment === "production") {
      setDatadogRumUser();
      setSentryUser();
      identifyLogRocketUser();
      const trackingUserData = getTrackingUserData();
      identify(trackingUserData);
    }
  }, [loggedUser]);
  const pathname = usePathname();
  const search = useSearchParams();
  React.useEffect(() => {
    const folder = findClosestRoute(pathname);
    track("page-view", {
      pathname,
      folder,
      search: search.toString(),
      success: true
    });
  }, [pathname, search]);
  return <>{props.children}</>;
}