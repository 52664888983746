"use client";

import LogRocket from "logrocket";
import { config } from "@/common/config";
import useLoggedUserFunctions from "@/hooks/useLoggedUserFunctions";
interface LogRocketUser {
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  organizationName: string;
  organizationId: string;
}
const SENSITIVE_PATTERNS = [/\/developer\/api-keys/];
export function initLogRocket() {
  LogRocket.init(config.logrocket.appId!, {
    release: config.logrocket.release,
    console: {
      isEnabled: {
        log: true,
        info: true,
        warn: true,
        error: true,
        debug: false
      },
      shouldAggregateConsoleErrors: true
    },
    network: {
      requestSanitizer: request => {
        if (SENSITIVE_PATTERNS.some(pattern => pattern.test(request.url))) {
          request.body = undefined;
          if (request.headers) {
            request.headers['authorization'] = undefined;
            request.headers['x-api-key'] = undefined;
          }
        }
        return request;
      },
      responseSanitizer: response => {
        if (SENSITIVE_PATTERNS.some(pattern => pattern.test(response.url))) {
          response.body = undefined;
        }
        return response;
      }
    },
    dom: {
      // Block recording of elements with these attributes
      privateAttributeBlocklist: ['data-sensitive', 'data-api-key'],
      // Block recording of elements with these classes
      privateClassNameBlocklist: ['sensitive-content', 'api-key-value']
    }
  });
}
export function identifyLogRocketUser() {
  const {
    getTrackingUserData
  } = useLoggedUserFunctions();
  const trackingUserData = getTrackingUserData() as LogRocketUser;
  if (trackingUserData?.userId) {
    LogRocket.identify(trackingUserData.userId, {
      ...trackingUserData,
      environment: config.environment
    });
  }
}
export function getLogRocketSessionURL(): string | null {
  try {
    return LogRocket.sessionURL;
  } catch {
    return null;
  }
}