import React from "react";

import { AcceptInvitationInput, Invitation } from "@/@codegen/supergraph";

import { toast } from "react-hot-toast";
import { useTranslations } from "next-intl";

import useAnalytics from "@/hooks/useAnalytics";

import { useInvitationRepository } from "@/repositories/invitationRepository";

/*
 * This is a hack to prevent us from accepting the invitation twice concurrently
 * This happens only locally when react renders twice, but we want to provide a better dev experience
 * There is no backend guard so two concurrent accepts will create two memberships
 */
let isAcceptingInvitation = false;

type InvitationOrId = Invitation | string;

export function useInvitationService() {
  const t = useTranslations();

  const invitationRepository = useInvitationRepository();
  const { track } = useAnalytics();

  async function getInvitation(invitationOrId: InvitationOrId) {
    const invitation = (
      typeof invitationOrId === "string"
        ? await invitationRepository.fetchInvitationById(invitationOrId)
        : invitationOrId
    ) as Invitation;
    return invitation;
  }

  async function getIsInvitationRevokedOrNonExistent(
    invitationOrId: InvitationOrId,
  ) {
    try {
      const invitation = await getInvitation(invitationOrId);
      if (invitation?.status === "REVOKED") {
        toast.error("The invitation has been revoked or does not exist");
        return true;
      }
      return false;
    } catch (e) {
      toast.error("The invitation has been revoked or does not exist");
      return true;
    }
  }

  async function acceptInvitation(invitationOrId: InvitationOrId) {
    if (isAcceptingInvitation) {
      return;
    }
    isAcceptingInvitation = true;
    const invitation = await getInvitation(invitationOrId);
    if (invitation?.status === "REVOKED") {
      isAcceptingInvitation = false;
      throw new Error("The invitation has been revoked");
    }

    if (invitation?.status === "PENDING") {
      return invitationRepository
        .acceptInvitation(invitation.id)
        .then((res: any) => {
          toast.success(
            "The invitation to join the organization has been accepted",
          );
          isAcceptingInvitation = false;
          return res;
        });
    }
    isAcceptingInvitation = false;
    return invitation;
    //@todo add datadog logging
  }

  async function signUpAndAcceptInvitation(input: AcceptInvitationInput) {
    const invitation = await getInvitation(input.invitationId);

    if (invitation?.status === "REVOKED") {
      throw new Error("The invitation has been revoked");
    }

    if (invitation?.status === "ACCEPTED") {
      throw new Error("The invitation has been accepted");
    }

    if (invitation?.status === "PENDING") {
      try {
        const response = await invitationRepository.signUpWithInvitation(input);

        track("user-sign-up", {
          email: invitation.email,
          success: true,
        });

        return response;
      } catch (error) {
        track("user-sign-up", {
          email: invitation.email,
          success: false,
        });
        throw error;
      }
    }
    //@todo add datadog logging
  }

  return {
    getInvitation,
    getIsInvitationRevokedOrNonExistent,
    acceptInvitation,
    signUpAndAcceptInvitation,
  };
}
