import useLocalStorage from "@/hooks/useLocalStorage";
import { setContext } from "@apollo/client/link/context";

export const authLink = setContext(async (_, { headers = {}, ...rest }) => {
  const authToken = useLocalStorage().getToken();

  const authHeaders = {
    Authorization: `Bearer ${authToken}`,
  };
  if (process.env.ENVIRONMENT === "development") {
    authHeaders["X-Texture-Supergraph-Secret"] =
      process.env.SUPERGRAPH_CLIENT_SECRET;
  }

  return {
    ...rest,
    headers: {
      ...headers,
      ...authHeaders,
    },
  };
});
