import { useSearchParams } from "next/navigation";

export function useUrlSearchParam(param: string) {
  const params = useSearchParams();
  return decodeURIComponent(params.get(param) || "");
}

type SearchParam = {
  param: string;
  value: string;
};
export function setUrlSearchParam(paramsToSet: SearchParam | SearchParam[]) {
  const searchParams = useSearchParams();
  const params = new URLSearchParams(searchParams);
  const parsedParams = Array.isArray(paramsToSet) ? paramsToSet : [paramsToSet];
  parsedParams.forEach(({ param, value }) => params.set(param, value));
}
