"use client";

import React from "react";
import { isNil } from "lodash";
import { usePathname } from "next/navigation";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import { useSelectedOrganization } from "@/state/user/useSelectedOrganization";
import { useLoggedUser } from "@/state/user/useLoggedUser";
import useLoggedUserFunctions from "@/hooks/useLoggedUserFunctions";
import { useNavigationService } from "@/services/navigationService";
import { useUrlSearchParam } from "@/hooks/useUrlSearchParam";
import { useUserService } from "@/services/userService";
import useLocalStorage from "@/hooks/useLocalStorage";
import { useAuthenticationService } from "@/services/authenticationService/index";
import { Loader } from "@/edges/ui/Loader";
import { useInvitationService } from "@/services/invitationService";
export interface SessionProviderProps {
  children: React.ReactNode;
}
export default function SessionProvider(props: SessionProviderProps) {
  const {
    loggedUser
  } = useLoggedUser();
  const {
    selectedOrganization
  } = useSelectedOrganization();
  const localStorage = useLocalStorage();
  const [_cookies, setCookie, removeCookie] = useCookies(["jwt"]);
  const navigationService = useNavigationService();
  useValidateInvitation();
  const {
    doneInitialValidation
  } = useValidateSession();
  const {
    loadingUserValidation
  } = useValidateUser(doneInitialValidation);

  // Sync JWT to cookie whenever it changes
  React.useEffect(() => {
    const jwt = localStorage.getToken();
    if (jwt) {
      try {
        const decoded = jwtDecode(jwt) as {
          exp: number;
        };
        const expires = new Date(decoded.exp * 1000);
        setCookie("jwt", jwt, {
          path: "/",
          expires,
          secure: process.env.NODE_ENV === "production",
          sameSite: "lax"
        });
      } catch (e) {
        console.error("Failed to set JWT cookie:", e);
      }
    } else {
      removeCookie("jwt", {
        path: "/"
      });
    }
  }, [loggedUser, setCookie, removeCookie]);
  if (!doneInitialValidation || loadingUserValidation) {
    return <div className="flex h-screen w-screen items-center justify-center">
        <Loader />
      </div>;
  }
  if (!navigationService.isPublicRoute() && (isNil(loggedUser) || isNil(selectedOrganization))) {
    return null;
  }
  return props.children;
}
const useVerifyUserPath = () => {
  const {
    loggedUser
  } = useLoggedUser();
  const navigationService = useNavigationService();
  const localStorage = useLocalStorage();
  const {
    isVerified,
    hasProductionOrganization
  } = useLoggedUserFunctions();
  const verifyUserPath = () => {
    if (!loggedUser) {
      if (!navigationService.isSigningRoute()) {
        if (localStorage.isThereAnyLocalData()) {
          navigationService.navigateToSignIn();
        } else {
          navigationService.navigateToSignUp();
        }
      }
    } else {
      if (!isVerified()) {
        if (!navigationService.isVerifyEmailRoute()) {
          navigationService.navigateToVerifyEmail();
        }
        return;
      }
      if (navigationService.isSigningRoute()) {
        navigationService.navigateToHomePage();
        return;
      }
      if (navigationService.isVerifyEmailRoute()) {
        navigationService.navigateToHomePage();
        return;
      }
      if (!hasProductionOrganization()) {
        navigationService.navigateToOrganizationOnboarding();
      }
    }
  };
  return {
    verifyUserPath
  };
};

/**
 * Constant validation of the user --- RACING CONDITION ---
 * We need to verify the user is on the correct page each time the loggedUser or pathname changes
 * But we want to make sure that this is a backup and that the main navigation is done where the process started
 * I considered adding apollo query to verify if anything is being done before we verifyUserPath, but the proper logic should be where the process started
 */
function useValidateUser(doneInitialValidation: boolean) {
  const {
    loggedUser
  } = useLoggedUser();
  const pathname = usePathname();
  const {
    verifyUserPath
  } = useVerifyUserPath();

  // todo: check below, switch to false when loggedUser object is cleaned
  const [loadingUserValidation, setLoadingUserValidation] = React.useState(true);
  const [timeoutHandler, setTimeoutHandler] = React.useState<any>(null);
  React.useEffect(() => {
    if (!doneInitialValidation) return;
    verifyUserPath();
    clearTimeout(timeoutHandler);
    setTimeoutHandler(null);
    setLoadingUserValidation(false);
  }, [pathname, doneInitialValidation]);
  React.useEffect(() => {
    /**
     * This is a hack. We want to recheck the user every time the loggedUser changes
     * But: we have the organization object tied to the user
     * Which means the children ( and routes for some nextjs reason ) will rerender if we force the loader
     * So for now, we do the recheck, but hold off the loader until we cleanup the object
     */
    if (!doneInitialValidation) return;
    // setLoadingUserValidation(true);
    const newTimeoutHandler = setTimeout(() => {
      verifyUserPath();
      setLoadingUserValidation(false);
    }, 500);
    setTimeoutHandler(newTimeoutHandler);
    return () => {
      clearTimeout(timeoutHandler);
      setTimeoutHandler(null);
      setLoadingUserValidation(false);
    };
  }, [loggedUser, doneInitialValidation]);
  return {
    loadingUserValidation
  };
}

/**
 * Initial validation of the session
 */
function useValidateSession() {
  const localStorage = useLocalStorage();
  const {
    verifyUserPath
  } = useVerifyUserPath();
  const {
    fetchLoggedUser
  } = useUserService();
  const {
    getIsAuthenticated,
    refreshSession
  } = useAuthenticationService();

  /**
   * Logic is as follows:
   * 1. First we either set a loggedUser object or not, but we store that we tried
   * 2. We know if the user is there, next effect block handles the initial routing check
   */
  const [verifyInitialUser, setVerifyInitialUser] = React.useState(false);
  const [verifyInitialPath, setVerifyInitialPath] = React.useState(false);
  /**
   * This part runs only once when the component is mounted
   * It checks if the user is authenticated and if so, it fetches the logged user
   * Either way, we mark that we performed this so that we can move on
   */
  React.useEffect(() => {
    const isAuthenticated = getIsAuthenticated();
    const refreshToken = localStorage.getRefreshToken();
    if (!isAuthenticated && refreshToken) {
      refreshSession().finally(() => {
        setVerifyInitialUser(true);
      });
    } else if (isAuthenticated) {
      fetchLoggedUser().finally(() => {
        setVerifyInitialUser(true);
      });
    } else {
      setVerifyInitialUser(true);
    }
  }, []);

  /**
   * This part only runs once when the initial user is verified or failed
   * It makes sure that the route the user came to is correct
   * Basically logged in user cannot go to the login flow and vice versa
   */
  React.useEffect(() => {
    if (!verifyInitialUser) return;
    verifyUserPath();
    setVerifyInitialPath(true);
  }, [verifyInitialUser]);
  return {
    doneInitialValidation: verifyInitialPath
  };
}

/**
 * This part only runs when the url has an invitation=SOME_ID
 * It is to verify if that invitation exists and is not revoked
 * If it does not exist or is revoked, the user is unauthorized to proceed
 * If it does exist and the user is logged in, we accept the invitation
 */
function useValidateInvitation() {
  // we want to keep the invitationId as a ref so that it doesn't get cleaned up
  const invitationId = React.useRef(useUrlSearchParam("invitation"));
  const invitationService = useInvitationService();
  const navigationService = useNavigationService();
  const {
    loggedUser
  } = useLoggedUser();
  const {
    refreshSession
  } = useAuthenticationService();

  // we don't want recursion with user object
  const [hasRefreshedSession, setHasRefreshedSession] = React.useState(false);
  React.useEffect(() => {
    if (!invitationId.current) return;
    invitationService.getIsInvitationRevokedOrNonExistent(invitationId.current).then(invitationRevokedOrNonExistent => {
      if (invitationRevokedOrNonExistent) {
        navigationService.navigateToSignIn(true);
      }
    });
  }, [invitationId.current]);
  React.useEffect(() => {
    if (!invitationId.current || !loggedUser || hasRefreshedSession) return;
    invitationService.acceptInvitation(invitationId.current).then((res: any) => {
      if (res?.organizationId) {
        refreshSession(res.organizationId).then(() => {
          setHasRefreshedSession(true);
        });
      }
    });
  }, [invitationId.current, loggedUser]);
  return {};
}