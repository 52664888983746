import React from "react";
import { classNames } from "@/utils";
interface LoaderProps {
  /**
   * Optional className for custom styling
   */
  className?: string;
  /**
   * Size of the loader in pixels
   * @default 24
   */
  size?: number;
  /**
   * Color of the loader
   * @default "text-primary"
   */
  color?: string;
}
export const Loader = ({
  className,
  size = 24,
  color = "text-primary"
}: LoaderProps) => {
  return <div className={classNames("inline-block animate-spin rounded-full border-2 border-solid border-current border-r-transparent", color, className)} style={{
    width: size,
    height: size
  }} role="status" aria-label="loading" data-sentry-component="Loader" data-sentry-source-file="Loader.tsx">
      <span className="sr-only">Loading...</span>
    </div>;
};