"use client";

import { ReactNode, useEffect, useState } from "react";
import { GrowthBook } from "@growthbook/growthbook";
import { GrowthBookProvider as GBProvider } from "@growthbook/growthbook-react";
import useLoggedUserFunctions from "@/hooks/useLoggedUserFunctions";
import { useLoggedUser } from "@/state/user/useLoggedUser";
interface GrowthBookProviderProps {
  children: ReactNode;
  initialFeatures: Record<string, any>;
}
const GrowthBookProvider = ({
  children,
  initialFeatures
}: GrowthBookProviderProps) => {
  const [growthbook, setGrowthbook] = useState<GrowthBook | null>(null);
  const {
    getTrackingUserData
  } = useLoggedUserFunctions();
  const {
    loggedUser
  } = useLoggedUser();
  useEffect(() => {
    const gb = new GrowthBook({
      apiHost: "https://cdn.growthbook.io",
      clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
      enableDevMode: process.env.NODE_ENV !== "production"
    });
    gb.setFeatures(initialFeatures);
    gb.loadFeatures({
      autoRefresh: true
    }).then(() => {
      setGrowthbook(gb);
    });
    return () => {
      gb.destroy();
    };
  }, [initialFeatures]);
  useEffect(() => {
    if (loggedUser && growthbook) {
      const trackingUserData = getTrackingUserData();
      growthbook.setAttributes(trackingUserData);
    }
  }, [loggedUser, growthbook]);
  if (!growthbook) {
    return null; // or a loading indicator
  }
  return <GBProvider growthbook={growthbook} data-sentry-element="GBProvider" data-sentry-component="GrowthBookProvider" data-sentry-source-file="GrowthBookProvider.tsx">{children}</GBProvider>;
};
export default GrowthBookProvider;