import {
  AcceptInvitationInput,
  CreateInvitationInput,
  OrganizationInvitationsInput,
  useAcceptInvitationMutation,
  useCreateInvitationMutation,
  useInvitationLazyQuery,
  useOrganizationInvitationsLazyQuery,
} from "@/@codegen/supergraph";
import {
  processGraphQLOperationErrorsAndThrow,
  processSingleOperationGraphQLResponse,
} from "@/utils";

export function useInvitationRepository() {
  const [invitationLazyQuery] = useInvitationLazyQuery();

  const [organizationInvitationsLazyQuery] =
    useOrganizationInvitationsLazyQuery();

  const [acceptOrganizationInvitationMutation] = useAcceptInvitationMutation();

  const [createOrganizationInvitationMutation] = useCreateInvitationMutation();

  function fetchInvitationById(invitationId: string) {
    return invitationLazyQuery({
      variables: {
        id: invitationId,
      },
    })
      .then(processSingleOperationGraphQLResponse("invitation"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  function acceptInvitation(invitationId: string) {
    return acceptOrganizationInvitationMutation({
      variables: {
        input: {
          invitationId,
        },
      },
    })
      .then(processSingleOperationGraphQLResponse("acceptInvitation"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  function signUpWithInvitation(input: AcceptInvitationInput) {
    return acceptOrganizationInvitationMutation({
      variables: {
        input,
      },
    })
      .then(processSingleOperationGraphQLResponse("acceptInvitation"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  function createInvitation(input: CreateInvitationInput) {
    return createOrganizationInvitationMutation({
      variables: {
        input,
      },
    })
      .then(processSingleOperationGraphQLResponse("createInvitation"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  function fetchInvitationsForOrganization(
    input: OrganizationInvitationsInput,
  ) {
    return organizationInvitationsLazyQuery({
      variables: {
        input,
      },
    })
      .then(processSingleOperationGraphQLResponse("organizationInvitations"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  return {
    fetchInvitationById,
    acceptInvitation,
    signUpWithInvitation,
    createInvitation,
    fetchInvitationsForOrganization,
  };
}
