import { IntlErrorCode, IntlError } from "next-intl";
// import useAnalytics from "@/hooks/useAnalytics";
import { config } from "@/common/config";

export function onError(error: IntlError) {
  if (config.environment !== "development") {
    return;
  }
  switch (error.code) {
    case IntlErrorCode.MISSING_MESSAGE:
      console.warn(error);
      break;
    case IntlErrorCode.MISSING_FORMAT:
      console.warn(error);
      break;
    case IntlErrorCode.ENVIRONMENT_FALLBACK:
      console.warn(error);
      break;
    case IntlErrorCode.INSUFFICIENT_PATH:
      // console.warn(error);
      break;
    // default:
    //   track(JSON.stringify(error), "missing translation");
  }
}

export function getMessageFallback({
  namespace,
  key,
  error,
}: {
  namespace?: string | undefined;
  key: string | null;
  error: IntlError;
}) {
  const path = [namespace, key].filter((part) => part != null).join(".");
  if (
    error.code === IntlErrorCode.MISSING_MESSAGE &&
    config.environment === "development"
  ) {
    return `${path}`;
  } else {
    return path;
  }
}
