import { setContext } from "@apollo/client/link/context";

/**
 * Add custom headers to requests from the Apollo client.
 */
export const customHeadersLink = setContext(
  async (_, { headers = {}, ...rest }) => {
    let userAgent = "Texture-Dashboard";

    // Ideally this would prepend to any existing user-agent, but for now I believe
    // it is just overwritten.
    if (headers.hasOwnProperty("user-agent")) {
      userAgent = `${userAgent} ${headers["user-agent"]}`;
    }

    const customHeaders = {
      "User-Agent": userAgent,
    };

    return {
      ...rest,
      headers: {
        ...headers,
        ...customHeaders,
      },
    };
  },
);
