"use client";

export default function Error({
  error,
  reset
}: {
  error: Error & {
    digest?: string;
  };
  reset: () => void;
}) {
  return <div data-sentry-component="Error" data-sentry-source-file="index.tsx">
      <h2>Something went wrong!</h2>
      <button onClick={() => reset()}>Try again</button>
    </div>;
}