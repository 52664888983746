"use client";

import React from "react";
import { usePathname, useSearchParams } from "next/navigation";
import useSessionStorage, { PREVIOUS_URL_STORAGE_KEY } from "@/hooks/useSessionStorage";
export interface NavigationProviderProps {
  children: React.ReactNode;
}

/**
 * This provider is used to store the previous URL that the user was on
 * In the future, we can use it to manage the user's navigation history, url state for filtering etc.
 */
export default function NavigationProvider(props: NavigationProviderProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const {
    setItem,
    getItem
  } = useSessionStorage();
  const [previousUrl, setPreviousUrl] = React.useState<string | null>(null);
  React.useEffect(() => {
    const previousUrl = getItem(PREVIOUS_URL_STORAGE_KEY);
    if (!previousUrl) return;
    setPreviousUrl(previousUrl);
  }, []);

  /**
   * Reason why we use a timeout is because we want to make sure that the previous url is set after the current url is set
   * We have some logic ( for example devices page ) that adds search params to the url after the url is set
   * If we don't use a timeout, we will store the wrong previous url
   */
  React.useEffect(() => {
    if (!previousUrl) return;
    const timeout = setTimeout(() => {
      const currentUrl = pathname + (searchParams.toString() ? `?${searchParams.toString()}` : "");
      setItem(PREVIOUS_URL_STORAGE_KEY, previousUrl);
      setPreviousUrl(currentUrl);
    }, 500);
    return () => clearTimeout(timeout);
  }, [pathname, searchParams]);
  return props.children;
}